import { useLocation } from "@reach/router";
import { graphql } from "gatsby"
import React, { Fragment } from "react"
import { useTranslation } from "react-i18next";
import ExperienceContainer from "../components/containers/ExperienceContainer";
import RecommendationsContainer from "../components/containers/RecommendationsContainer";
import Layout from "../components/Layout"
import Link from "../components/Link";
import Seo from "../components/SEO";
import { usePageContext } from "../pageContext";
import Projects from "../providers/Projects";

function WorkPage({ data }) {

  console.log(data);
  const { project, dataS } = data;

  const projectDetails = Projects.find(p => p.slug == project.slug);
  const currentIndex = Projects.findIndex(p => p.slug == project.slug);

  let prev = null;
  let next = null;

  if (currentIndex < Projects.length - 1) {
    next = Projects[currentIndex + 1];
  }

  if (currentIndex > 0) {
    prev = Projects[currentIndex - 1];
  }

  const languageData = useTranslation()[1].store.data;

  const { lang } = usePageContext();

  const echo = (expression) => {
    return languageData[lang]['projectPage'][expression];
  }


  return (
    <Layout data={{ site: dataS }} lang={lang}>

      <Seo lang={lang} title={`${projectDetails.title[lang]} | Hanniel TSASSE`} description={projectDetails.description[lang]} image={projectDetails.image.src} />

      <Fragment>
        {/* <!-- container --> */}
        <div className="container-fluid">

          {/* <!-- row --> */}
          <div className="row p-30-0">

            {/* <!-- col --> */}
            <div className="col-lg-12">

              {/* <!-- section title --> */}
              <div className="art-section-title">
                {/* <!-- title frame --> */}
                <div className="art-title-frame">
                  {/* <!-- title --> */}
                  <h4>{projectDetails.title[lang]}</h4>
                </div>
                {/* <!-- title frame end --> */}
                {/* <!-- right frame --> */}
                <div className="art-right-frame">
                  <div className="art-project-category">{projectDetails.category[lang]}</div>
                </div>
                {/* <!-- right frame end --> */}
              </div>
              {/* <!-- section title end --> */}

            </div>
            {/* <!-- col end --> */}

            {/* <!-- col --> */}
            <div className="col-lg-12">

              {/* <!-- slider container --> */}
              <div className="swiper-container art-works-slider" style={{ overflow: 'hidden' }}>
                {/* <!-- slider wrapper --> */}
                <div className="swiper-wrapper">
                  {projectDetails.illustrations.map((illustration, index) => (
                    <div className="swiper-slide" key={index}>
                      {/* <!-- item frame --> */}
                      <a data-fancybox="gallery" href={illustration.src} className="art-a art-portfolio-item-frame art-horizontal">
                        {/* <!-- img --> */}
                        <img src={illustration.image} alt="item" />
                        {/* <!-- zoom icon --> */}
                        <span className="art-item-hover"><i className="fas fa-expand"></i></span>
                      </a>
                      {/* <!-- item end --> */}
                    </div>

                  ))}
                </div>
                {/* <!-- slider wrapper end --> */}
              </div>
              {/* <!-- slider container end --> */}

            </div>
            {/* <!-- col end --> */}

            {/* <!-- col --> */}
            <div className="col-lg-12">

              {/* <!-- slider navigation --> */}
              <div className="art-slider-navigation">

                {/* <!-- left side --> */}
                <div className="art-sn-left">

                  {/* <!-- slider pagination --> */}
                  <div className="swiper-pagination"></div>

                </div>
                {/* <!-- left side end --> */}

                {/* <!-- right side --> */}
                <div className="art-sn-right">

                  {/* <!-- slider navigation --> */}
                  <div className="art-slider-nav-frame">
                    {/* <!-- prev --> */}
                    <div className="art-slider-nav art-works-swiper-prev"><i className="fas fa-chevron-left"></i></div>
                    {/* <!-- next --> */}
                    <div className="art-slider-nav art-works-swiper-next"><i className="fas fa-chevron-right"></i></div>
                  </div>
                  {/* <!-- slider navigation --> */}

                </div>
                {/* <!-- right side end --> */}

              </div>
              {/* <!-- slider navigation end --> */}

            </div>
            {/* <!-- col end --> */}

          </div>
          {/* <!-- row end --> */}

        </div>
        {/* <!-- container end --> */}

        {/* <!-- container --> */}
        <div className="container-fluid">

          {/* <!-- row --> */}
          <div className="row p-30-0">

            {/* <!-- col --> */}
            <div className="col-lg-12">

              {/* <!-- section title --> */}
              <div className="art-section-title">
                {/* <!-- title frame --> */}
                <div className="art-title-frame">
                  {/* <!-- title --> */}
                  <h4>{echo('details')}</h4>
                </div>
                {/* <!-- title frame end --> */}
              </div>
              {/* <!-- section title end --> */}

            </div>
            {/* <!-- col end --> */}

            {/* <!-- col --> */}
            <div className="col-lg-8">

              <div className="art-a art-card art-fluid-card">
                <h5 className="mb-15">Description</h5>
                <div className="mb-15">{projectDetails.fullDescription[lang]}</div>
                {/* <!-- button --> */}
                {projectDetails.file != undefined && <a href={projectDetails.file} download className="art-btn art-btn-md" style={{ alignItems: 'center', display: 'flex' }}> <i className='fa fa-download' style={{ fontSize: '1.4em', paddingRight: '10px' }}></i> <span>{echo('test')}</span></a>}

                {/* <div className="art-buttons-frame"><a href="https://www.behance.net/gallery/83236527/VIE" className="art-link art-color-link art-w-chevron" target="_blank">Lire Plus</a></div> */}
              </div>

            </div>
            {/* <!-- col end --> */}

            {/* <!-- col --> */}
            <div className="col-lg-4">

              <div className="art-a art-card">
                {/* <!-- table --> */}
                <div className="art-table p-15-15">
                  <ul>
                    <li>
                      <h6>{echo('date.order')} :</h6><span>{projectDetails.infos.started_at}</span>
                    </li>
                    <li>
                      <h6>{echo('date.end')} :</h6><span>{projectDetails.infos.finished_at}</span>
                    </li>
                    <li>
                      <h6>Status :</h6><span>{projectDetails.infos.status}</span>
                    </li>
                    <li>
                      <h6>Client :</h6><span>{projectDetails.infos.client}</span>
                    </li>
                    <li>
                      <h6>{echo('price')} :</h6><span>{projectDetails.infos.price}</span>
                    </li>
                    {/* <li>
                      <h6>Location:</h6><span><a href="https://goo.gl/maps/b4w13Zuk9iWCsLMX7" target="_blank">Ukraine, Lviv</a></span>
                    </li> */}
                  </ul>
                </div>
                {/* <!-- table end --> */}
              </div>

            </div>
            {/* <!-- col end --> */}

          </div>
          {/* <!-- row end --> */}


        </div>
        {/* <!-- container end --> */}

        <RecommendationsContainer lang={lang} />
        <ExperienceContainer lang={lang} />

        <div className="container-fluid">

          {/* <!-- row --> */}
          <div className="row">

            {/* <!-- col --> */}
            <div className="col-lg-12">

              {/* <!-- call to action --> */}
              <div className="art-a art-banner" style={{ backgroundImage: 'url(/assets/img/bg.jpg)' }}>
                {/* <!-- overlay --> */}
                <div className="art-banner-overlay">
                  {/* <!-- main title --> */}
                  <div className="art-banner-title text-center">
                    {/* <!-- title --> */}
                    <h1 className="mb-15">{echo('ready')}</h1>
                    {/* <!-- suptitle --> */}
                    <div className="art-lg-text art-code mb-25">{echo('works')}</div>
                    {/* <!-- button --> */}
                    <Link to="/contactez-moi" className="art-btn art-btn-md"><span>{echo('contact')}</span></Link>
                  </div>
                  {/* <!-- main title end --> */}
                </div>
                {/* <!-- overlay end --> */}
              </div>
              {/* <!-- call to action end  --> */}

              {/* <!-- projects navigation --> */}
              <div className="art-a art-pagination">

                <Link to={`/mes-travaux/${prev != null ? prev.slug : projectDetails.slug}`} className="art-link art-color-link art-w-chevron art-left-link"><span>{lang == 'fr' ? 'Projet précédent' : 'Previous project'}</span></Link>

                <div className="art-pagination-center art-m-hidden">
                  <Link className="art-link" to="/mes-travaux">{lang == 'fr' ? 'Tous les projets' : 'All projects'}</Link>
                </div>

                <Link to={`/mes-travaux/${next != null ? next.slug : projectDetails.slug}`} className="art-link art-color-link art-w-chevron"><span>{lang == 'fr' ? 'Projet suivant' : 'Next project'}</span></Link>

              </div>
              {/* <!-- projects navigation end --> */}

            </div>
            {/* <!-- col end --> */}

          </div>
          {/* <!-- row end --> */}

        </div>

      </Fragment>
    </Layout>
  )
}

export default WorkPage

export const query = graphql`
  query WorkPage($slug: String!) {
    project: projectsJson(slug: { eq: $slug }) {
        slug
    }

    dataS: site {
      siteMetadata {
        siteURL
      }
    }
  }
`