const Projects = [
    {
        slug: 'walners-startup',
        categories: 'webApps mobileApps',
        category: {
            en: 'Web and mobile platform',
            fr: 'Plateforme Web et Mobile'
        },
        image: {
            src: 'assets/img/works/original-size/walners-startup.jpg',
            original: '/assets/img/works/original-size/walners-startup.jpg',
            small: '/assets/img/works/original-size/walners-startup.jpg',
        },
        illustrations: [
            {
                image: '/assets/img/works/projects/walners-startup-1.jpg',
                src: './../../../assets/img/works/projects/walners-startup-1.jpg'
            },
            {
                image: '/assets/img/works/projects/walners-startup-2.jpg',
                src: './../../../assets/img/works/projects/walners-startup-2.jpg'
            },
            {
                image: '/assets/img/works/projects/walners-startup-3.jpg',
                src: './../../../assets/img/works/projects/walners-startup-3.jpg'
            },
            {
                image: '/assets/img/works/projects/walners-startup-4.jpg',
                src: './../../../assets/img/works/projects/walners-startup-4.jpg'
            },
            {
                image: '/assets/img/works/projects/walners-startup-5.jpg',
                src: './../../../assets/img/works/projects/walners-startup-5.jpg'
            },
            {
                image: '/assets/img/works/projects/walners-startup-6.jpg',
                src: './../../../assets/img/works/projects/walners-startup-6.jpg'
            },
            {
                image: '/assets/img/works/projects/walners-startup-7.jpg',
                src: './../../../assets/img/works/projects/walners-startup-7.jpg'
            },
            {
                image: '/assets/img/works/projects/walners-startup-8.jpg',
                src: './../../../assets/img/works/projects/walners-startup-8.jpg'
            }
        ],
        title: {
            fr: "WALNERS STARTUP : Startup spécialisée dans le domaine du bâtiment",
            en: "WALNERS STARTUP : Startup specializing in the building industry"
        },
        description: {
            fr: "Plateforme destinée aux personnes voulant construire, recherchant des matériaux de construction, des services informatique.",
            en: 'Platform intended for people wanting to build, looking for building materials, IT services.'
        },
        fullDescription: {
            fr: "Plateforme destinée aux personnes voulant construire, recherchant des matériaux de construction, des services informatique. En effet, l'utilisateur créé un compte sur l'application, il a accès à la liste des entreprises de constructions enregistrées sur la plateforme. En fonction des réalisations de celles ci il peut en choisir une et la contacter. La plateforme intègre aussi un système de suivi du projet.",
            en: "Platform intended for people wanting to build, looking for building materials, IT services. Indeed, the user creates an account on the application, he has access to the list of construction companies registered on the platform. Depending on their achievements, he can choose one and contact her. The platform also integrates a project monitoring system."
        },
        infos: {
            started_at: '13-04-2021',
            finished_at: '06-06-2021',
            status: 'En cours',
            client: 'WALNERS STARTUP',
            price: 'N/A'
        }
    },
    {
        slug: 'meditrack-lostho',
        categories: 'webApps mobileApps',
        category: {
            fr: 'Application Mobile & Web',
            en: 'Mobile & Web Application'
        },
        image: {
            src: 'assets/img/works/original-size/lostho.jpg',
            original: '/assets/img/works/original-size/lostho.jpg',
            small: '/assets/img/works/original-size/lostho.jpg',
        },
        illustrations: [
            {
                image: '/assets/img/works/projects/lostho-1.jpg',
                src: './../../../assets/img/works/projects/lostho-1.jpg'
            },
            {
                image: '/assets/img/works/projects/lostho-2.jpg',
                src: './../../../assets/img/works/projects/lostho-2.jpg'
            },
            {
                image: '/assets/img/works/projects/lostho-3.jpg',
                src: './../../../assets/img/works/projects/lostho-3.jpg'
            },
            {
                image: '/assets/img/works/projects/lostho-4.jpg',
                src: './../../../assets/img/works/projects/lostho-4.jpg'
            },
            {
                image: '/assets/img/works/projects/lostho-5.jpg',
                src: './../../../assets/img/works/projects/lostho-5.jpg'
            },
            {
                image: '/assets/img/works/projects/lostho-6.jpg',
                src: './../../../assets/img/works/projects/lostho-6.jpg'
            },
            {
                image: '/assets/img/works/projects/lostho-7.jpg',
                src: './../../../assets/img/works/projects/lostho-7.jpg'
            },
            {
                image: '/assets/img/works/projects/lostho-8.jpg',
                src: './../../../assets/img/works/projects/lostho-8.jpg'
            },
            {
                image: '/assets/img/works/projects/lostho-9.jpg',
                src: './../../../assets/img/works/projects/lostho-9.jpg'
            },
            {
                image: '/assets/img/works/projects/lostho-10.jpg',
                src: './../../../assets/img/works/projects/lostho-10.jpg'
            },
            {
                image: '/assets/img/works/projects/lostho-11.jpg',
                src: './../../../assets/img/works/projects/lostho-11.jpg'
            },
            {
                image: '/assets/img/works/projects/lostho-12.jpg',
                src: './../../../assets/img/works/projects/lostho-12.jpg'
            },
            {
                image: '/assets/img/works/projects/lostho-13.jpg',
                src: './../../../assets/img/works/projects/lostho-13.jpg'
            },
        ],
        title: {
            fr: "LOSTHO : Application de géolocalisation des pharmacies",
            en: "LOSTHO : Pharmacies locator app"
        },
        description: {
            fr: "Plateforme permettant aux patients de trouver facilement les médicaments qu'ils recherchent.",
            en: "Platform that makes it easy for patients to find the drugs they are looking for."
        },
        fullDescription: {
            fr: "Plateforme permettant aux patients de géolocaliser les pharmacies disposant des médicaments qu'ils recherchent. À partir de ces résultats les gestionnaires seront capables de prendre des décisions stratégiques, et les patients de savoir exactement où se trouve le médicament qu’ils recherchent. Entre autres, l'application permet également de se faire consulter par des docteurs disponibles par appel vidéo ou encore des conseils par messagerie.",
            en: "Platform allowing patients to geolocate pharmacies with the drugs they are looking for. From these results managers will be able to make strategic decisions, and patients will know exactly where the drug they are looking for is located. Among other things, the application also makes it possible to be consulted by doctors available by video call or even advice by messaging."
        },
        infos: {
            started_at: '18-02-2021',
            finished_at: '12-04-2021',
            status: 'Terminé',
            client: 'Hanniel TSASSE',
            price: 'N/A'
        }
    },
    {
        slug: 'jeu-pendu',
        categories: 'mobileApps',
        category: {
            fr: 'Jeu Mobile',
            en: 'Mobile Game'
        },
        image: {
            src: 'assets/img/works/original-size/pendu.jpg',
            original: '/assets/img/works/original-size/pendu.jpg',
            small: '/assets/img/works/original-size/pendu.jpg',
        },
        illustrations: [
            {
                image: '/assets/img/works/projects/pendu-1.jpg',
                src: './../../../assets/img/works/projects/pendu-1.jpg'
            },
            {
                image: '/assets/img/works/projects/pendu-2.jpg',
                src: './../../../assets/img/works/projects/pendu-2.jpg'
            },
            {
                image: '/assets/img/works/projects/pendu-3.jpg',
                src: './../../../assets/img/works/projects/pendu-3.jpg'
            },
        ],
        title: {
            fr: "Jeu du pendu : Devinez le mot",
            en: 'Hangman Game: Guess the Word'
        },
        description: {
            fr: "Le Pendu est un jeu consistant à trouver un mot en devinant quelles sont les lettres qui le composent.",
            en: 'Hangman is a game of finding a word by guessing which letters are in it.'
        },
        fullDescription: {
            fr: "Le Pendu est un jeu consistant à trouver un mot en devinant quelles sont les lettres qui le composent. Le jeu se joue traditionnellement à deux, avec un papier et un crayon, selon un déroulement bien particulier. Dans ce jeu, la machine propose des mots à deviner et affiche une liste de caractères. Lorsque le caractère choisi par l'utilisateur ne figure pas dans la liste des caractères que compose le mot, on continue de dévoiler le bonhomme pendu.",
            en: "Hangman is a game of finding a word by guessing which letters are in it. The game is traditionally played in pairs, with paper and pencil, according to a very specific sequence. In this game, the machine suggests words to guess and displays a list of characters. When the character chosen by the user does not appear in the list of characters that make up the word, we continue to reveal the hanged man."
        },
        infos: {
            started_at: '18-07-2020',
            finished_at: '12-08-2020',
            status: 'Terminé',
            client: 'Hanniel TSASSE',
            price: 'N/A'
        }
    },
    {
        slug: 'codons-vivant',
        categories: 'mobileApps',
        file: '/assets/files/code4geeks.apk',
        category: {
            fr: 'Application de E-learning',
            en: 'E-learning Application'
        },
        image: {
            src: 'assets/img/works/original-size/code4geeks.jpg',
            original: '/assets/img/works/original-size/code4geeks.jpg',
            small: '/assets/img/works/original-size/code4geeks.jpg',
        },
        illustrations: [
            {
                image: '/assets/img/works/projects/code4geeks-1.jpg',
                src: './../../../assets/img/works/projects/code4geeks-1.jpg'
            },
            {
                image: '/assets/img/works/projects/code4geeks-2.jpg',
                src: './../../../assets/img/works/projects/code4geeks-2.jpg'
            },
            {
                image: '/assets/img/works/projects/code4geeks-3.jpg',
                src: './../../../assets/img/works/projects/code4geeks-3.jpg'
            },
            {
                image: '/assets/img/works/projects/code4geeks-4.jpg',
                src: './../../../assets/img/works/projects/code4geeks-4.jpg'
            },
            {
                image: '/assets/img/works/projects/code4geeks-5.jpg',
                src: './../../../assets/img/works/projects/code4geeks-5.jpg'
            },
            {
                image: '/assets/img/works/projects/code4geeks-6.jpg',
                src: './../../../assets/img/works/projects/code4geeks-6.jpg'
            },
            {
                image: '/assets/img/works/projects/code4geeks-7.jpg',
                src: './../../../assets/img/works/projects/code4geeks-7.jpg'
            },
            {
                image: '/assets/img/works/projects/code4geeks-8.jpg',
                src: './../../../assets/img/works/projects/code4geeks-8.jpg'
            }
        ],
        title: {
            fr: "CODE4GEEKS : Application de E-learning",
            en: 'CODE4GEEKS : E-learning Application'
        },
        description: {
            fr: "Plateforme de e-learning dans le domaine de l'informatique.",
            en: "E-learning platform in the field of IT."
        },
        fullDescription: {
            fr: "Plateforme de E-learning spécialisée dans le domaine de la prorammation informatique. En plus des vidéos de formations, l'application se présente comme un réseau social avec sa partie forum et sa partie chat privée pour de meilleures échanges entre les utilisateurs.",
            en: "E-learning platform specialized in the field of IT prorammation. In addition to training videos, the application is presented as a social network with its forum section and its private chat section for better exchanges between users."
        },
        infos: {
            started_at: '10-02-2020',
            finished_at: '12-05-2020',
            status: 'Terminé',
            client: 'Hanniel TSASSE',
            price: 'N/A'
        }
    },
    {
        slug: 'jiro-shopping',
        categories: 'mobileApps',
        category: {
            fr: "Plateforme e-commerce multivendeur",
            en: "Multi-seller e-commerce platform"
        },
        image: {
            src: 'assets/img/works/original-size/jiro.jpg',
            original: '/assets/img/works/original-size/jiro.jpg',
            small: '/assets/img/works/original-size/jiro.jpg',
        },
        illustrations: [
            {
                image: '/assets/img/works/projects/jiro-1.jpg',
                src: './../../../assets/img/works/projects/jiro-1.jpg'
            },
            {
                image: '/assets/img/works/projects/jiro-2.jpg',
                src: './../../../assets/img/works/projects/jiro-2.jpg'
            },
            {
                image: '/assets/img/works/projects/jiro-3.jpg',
                src: './../../../assets/img/works/projects/jiro-3.jpg'
            },
            {
                image: '/assets/img/works/projects/jiro-4.jpg',
                src: './../../../assets/img/works/projects/jiro-4.jpg'
            },
            {
                image: '/assets/img/works/projects/jiro-5.jpg',
                src: './../../../assets/img/works/projects/jiro-5.jpg'
            },
            {
                image: '/assets/img/works/projects/jiro-6.jpg',
                src: './../../../assets/img/works/projects/jiro-6.jpg'
            },
            {
                image: '/assets/img/works/projects/jiro-7.jpg',
                src: './../../../assets/img/works/projects/jiro-7.jpg'
            },
            {
                image: '/assets/img/works/projects/jiro-8.jpg',
                src: './../../../assets/img/works/projects/jiro-8.jpg'
            },
            {
                image: '/assets/img/works/projects/jiro-9.jpg',
                src: './../../../assets/img/works/projects/jiro-9.jpg'
            },
            {
                image: '/assets/img/works/projects/jiro-10.jpg',
                src: './../../../assets/img/works/projects/jiro-10.jpg'
            },
        ],
        title: {
            fr: "Plateforme e-commerce multivendeur",
            en: "Multi-seller e-commerce platform"
        },
        description: {
            fr: "Une plateforme permettant de connecter ceux désirant d'acheter des produits à ceux désirant vendre leurs produits.",
            en: "A platform to connect those wishing to buy products to those wishing to sell their products."
        },
        fullDescription: {
            fr: "Une plateforme permettant de connecter ceux désirant d'acheter des produits à ceux désirant vendre leurs produits. En effet, chaque utilisateur ayant un compte sur la plateforme est capable de créer sa propre boutique, y acjouter des produits et les manager. Il peut entre autre ajouter des promotions, consulter les avis des utilisateurs, recevoir des commandes via le système de chat intégré dans l'application.",
            en: "A platform to connect those wishing to buy products to those wishing to sell their products. Indeed, each user with an account on the platform is able to create his own store, add products and manage them. He can, among other things, add promotions, consult user reviews, receive orders via the chat system integrated into the application."
        },
        infos: {
            started_at: '18-11-2019',
            finished_at: '12-12-2019',
            status: 'Terminé',
            client: 'Hanniel TSASSE',
            price: 'N/A'
        }
    },
    {
        slug: 'pays-capitales',
        categories: 'webApps mobileApps',
        file: '/assets/files/pays-capitales.apk',
        category: {
            fr: "Jeu de culture générale",
            en: "General culture game"
        },
        image: {
            src: 'assets/img/works/original-size/pays-capitales.jpg',
            original: '/assets/img/works/original-size/pays-capitales.jpg',
            small: '/assets/img/works/original-size/pays-capitales.jpg',
        },
        illustrations: [
            {
                image: '/assets/img/works/projects/pays-capitales-1.jpg',
                src: './../../../assets/img/works/projects/pays-capitales-1.jpg'
            },
            {
                image: '/assets/img/works/projects/pays-capitales-2.jpg',
                src: './../../../assets/img/works/projects/pays-capitales-2.jpg'
            },
            {
                image: '/assets/img/works/projects/pays-capitales-3.jpg',
                src: './../../../assets/img/works/projects/pays-capitales-3.jpg'
            },
            {
                image: '/assets/img/works/projects/pays-capitales-4.jpg',
                src: './../../../assets/img/works/projects/pays-capitales-4.jpg'
            },
            {
                image: '/assets/img/works/projects/pays-capitales-5.jpg',
                src: './../../../assets/img/works/projects/pays-capitales-5.jpg'
            },
            {
                image: '/assets/img/works/projects/pays-capitales-6.jpg',
                src: './../../../assets/img/works/projects/pays-capitales-6.jpg'
            },
            {
                image: '/assets/img/works/projects/pays-capitales-7.jpg',
                src: './../../../assets/img/works/projects/pays-capitales-7.jpg'
            },
        ],
        title: {
            fr: "Jeu de culture générale (pays - drapeaux - capitales)",
            en: "General culture game (countries - flags - capitals)"
        },
        description: {
            fr: "Un jeu permettant de se cultiver. Notamment en apprenant les pays du monde et leurs drapeaux, ainsi que leurs capitales.",
            en: "A game for learning. In particular by learning the countries of the world and their flags, as well as their capitals."
        },
        fullDescription: {
            fr: "Un jeu permettant de se cultiver. Notamment en apprenant les pays du monde et leurs drapeaux, ainsi que leurs capitales. En effet, l'application présente deux volets : L'apprentissage et le jeu. Dans le mode apprentissage l'utilisateur peut consulter la liste de tous les pays du monde par région (continent, leurs capitales et drapeaux. Le mode jeu se présente sous forme de QCM (Questions à Choix Multiples). L'utilisateur a accès à ses scores pour voir son évolution. Comme suplément, au cours du jeu l'utilisateur peut acheter de indices via les pieces qu'il récolte.",
            en: "A game for learning. In particular by learning the countries of the world and their flags, as well as their capitals. In fact, the application has two components: Learning and playing. In learning mode, the user can consult the list of all the countries in the world by region (continent, their capitals and flags. The game mode is presented in the form of MCQ(Multiple Choice Questions). The user has access to his scores to see his progress. As a supplement, during the game the user can buy clues via the coins he collects."
        },
        infos: {
            started_at: '18-11-2019',
            finished_at: '12-12-2019',
            status: 'Terminé',
            client: 'Hanniel TSASSE',
            price: 'N/A'
        }
    }
];

export default Projects;