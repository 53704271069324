import React from 'react'

function TestimonialItem({ lang }) {
    return (
        <div className="swiper-slide">

            {/* <!-- testimonial --> */}
            <div className="art-a art-testimonial">
                {/* <!-- testimonial body --> */}
                <div className="testimonial-body">
                    {/* <!-- photo --> */}
                    <img className="art-testimonial-face" src={"/assets/img/face-1.jpg"} alt="face" />
                    {/* <!-- name --> */}
                    <h5>Michel KENNE</h5>
                    <div className="art-el-suptitle mb-15">{lang == 'fr' ? "Directeur de ..." : "... Director"}</div>
                    {/* <!-- text --> */}
                    <div className="mb-15">{lang == 'fr' ? "Travailler avec Hanniel a été un plaisir. Mieux encore - je les ai alertés d'un problème mineur avant d'aller m'endormir. Le problème a été résolu le lendemain matin. Je ne pourrais pas demander un meilleur soutien. Merci Hanniel !" : "Working with Hanniel has been a pleasure. Better yet - I alerted them to a minor issue before I went to sleep. The problem was resolved the next morning. I couldn't ask for better support. Thanks Hanniel!"}</div>
                </div>
                {/* <!-- testimonial body end --> */}
                {/* <!-- testimonial footer --> */}
                <div className="art-testimonial-footer">
                    <div className="art-left-side">
                        {/* <!-- star rate --> */}
                        <ul className="art-star-rate">
                            <li><i className="fas fa-star"></i></li>
                            <li><i className="fas fa-star"></i></li>
                            <li><i className="fas fa-star"></i></li>
                            <li><i className="fas fa-star"></i></li>
                            <li><i className="fas fa-star"></i></li>
                        </ul>
                        {/* <!-- star rate end --> */}
                    </div>
                    <div className="art-right-side">

                    </div>
                </div>
                {/* <!-- testimonial footer end --> */}
            </div>
            {/* <!-- testimonial end --> */}

        </div>

    )
}

export default TestimonialItem
