import React from 'react'
import { useTranslation } from 'react-i18next';
import { usePageContext } from '../../pageContext';

function ExperienceContainer({ lang }) {

    const languageData = useTranslation()[1].store.data;

    const echo = (expression) => {
        return languageData[lang]['experienceContainer'][expression];
    }

    return (
        <div className="container-fluid">

            {/* <!-- row --> */}
            <div className="row p-30-0">

                {/* <!-- col --> */}
                <div className="col-md-3 col-6">

                    {/* <!-- couner frame --> */}
                    <div className="art-counter-frame">
                        {/* <!-- counter --> */}
                        <div className="art-counter-box">
                            {/* <!-- counter number --> */}
                            <span className="art-counter">5</span><span className="art-counter-plus">+</span>
                        </div>
                        {/* <!-- counter end --> */}
                        {/* <!-- title --> */}
                        <h6>{echo('years')}</h6>
                    </div>
                    {/* <!-- couner frame end --> */}

                </div>
                {/* <!-- col end --> */}

                {/* <!-- col --> */}
                <div className="col-md-3 col-6">

                    {/* <!-- couner frame --> */}
                    <div className="art-counter-frame">
                        {/* <!-- counter --> */}
                        <div className="art-counter-box">
                            {/* <!-- counter number --> */}
                            <span className="art-counter">17</span>
                        </div>
                        {/* <!-- counter end --> */}
                        {/* <!-- title --> */}
                        <h6>{echo('projects')}</h6>
                    </div>
                    {/* <!-- couner frame end --> */}

                </div>
                {/* <!-- col end --> */}

                {/* <!-- col --> */}
                <div className="col-md-3 col-6">

                    {/* <!-- couner frame --> */}
                    <div className="art-counter-frame">
                        {/* <!-- counter --> */}
                        <div className="art-counter-box">
                            {/* <!-- counter number --> */}
                            <span className="art-counter">8</span>
                        </div>
                        {/* <!-- counter end --> */}
                        {/* <!-- title --> */}
                        <h6>{echo('clients')}</h6>
                    </div>
                    {/* <!-- couner frame end --> */}

                </div>
                {/* <!-- col end --> */}

                {/* <!-- col --> */}
                <div className="col-md-3 col-6">

                    {/* <!-- couner frame --> */}
                    <div className="art-counter-frame">
                        {/* <!-- counter --> */}
                        <div className="art-counter-box">
                            {/* <!-- counter number --> */}
                            <span className="art-counter">2</span><span className="art-counter-plus">+</span>
                        </div>
                        {/* <!-- counter end --> */}
                        {/* <!-- title --> */}
                        <h6>{echo('certifications')}</h6>
                    </div>
                    {/* <!-- couner frame end --> */}

                </div>
                {/* <!-- col end --> */}

            </div>
            {/* <!-- row end --> */}

        </div>

    )
}

export default ExperienceContainer
